<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
// import pageMain from './pages'

export default {
  name: 'App',
  components: {
    // pageMain
  }
}
</script>

<style lang="scss">
hmtl,
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  letter-spacing: 1px;
}
#app {
}
</style>
