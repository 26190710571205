import VueRouter from 'vue-router'

const router = new VueRouter({
  routes: [
    //配置路由的路径
    {
      path: '/',
      component: () => import('@/pages/index.vue'),
      children: [
        {
          path: '',
          name: 'Home',
          component: () => import('@/pages/homePage/index.vue')
        },
        {
          path: '/communities',
          name: 'Communities',
          component: () => import('@/pages/communities/index.vue')
        },
        {
          path: '/news',
          name: 'News',
          component: () => import('@/pages/news/index.vue')
        },
        {
          path: '/purchaseOfLand',
          name: 'PurchaseOfLand',
          component: () => import('@/pages/PurchaseOfLand/index.vue')
        },
        {
          path: '/sustainability',
          name: 'Sustainability',
          component: () => import('@/pages/SustainabilityPage/index.vue')
        },
        {
          path: '/whatWeDo',
          name: 'WhatWeDo',
          component: () => import('@/pages/WhatWeDo/index.vue')
        },
        {
          path: '/purchaseOfLand',
          name: 'PurchaseOfLand',
          component: () => import('@/pages/PurchaseOfLand/index.vue')
        },
        {
          path: '/detail',
          name: 'Detail',
          component: () => import('@/pages/components/detail.vue')
        },
        {
          path: '/environment',
          name: 'Environment',
          component: () => import('@/pages/components/environment.vue')
        },
        {
          path: '/meetCeo',
          name: 'MeetCeo',
          component: () => import('@/pages/components/meetCeo.vue')
        },
        {
          path: '/missions',
          name: 'Missions',
          component: () => import('@/pages/components/Missions.vue')
        },
        {
          path: '/ourHistory',
          name: 'OurHistory',
          component: () => import('@/pages/components/ourHistory.vue')
        },
        {
          path: '/personDetail',
          name: 'PersonDetail',
          component: () => import('@/pages/components/person-detail.vue')
        },
        {
          path: '/reportDetail',
          name: 'ReportDetail',
          component: () => import('@/pages/components/reportDetail.vue')
        },
        {
          path: '/warming',
          name: 'Warming',
          component: () => import('@/pages/components/warming.vue')
        },
        {
          path: '/ismsPolicy',
          name: 'IsmsPolicy',
          component: () => import('@/pages/ismsPolicy/index.vue')
        },
        {
          path: '/csrReport',
          name: 'CsrReport',
          component: () => import('@/pages/csrReport/index.vue')
        },
        {
          path: '/EsgReport',
          name: 'EsgReport',
          component: () => import('@/pages/esgReport/index.vue')
        },
        {
          path: '/pressRelease',
          name: 'PressRelease',
          component: () => import('@/pages/pressRelease/index.vue')
        },
        {
          path: '/donations',
          name: 'Donations',
          component: () => import('@/pages/donations/index.vue')
        },
        {
          path: '/events',
          name: 'Events',
          component: () => import('@/pages/events/index.vue')
        },
        {
          path: '/employment',
          name: 'Employment',
          component: () => import('@/pages/employment/index.vue')
        },
        {
          path: '/SA8000',
          name: 'SA8000',
          component: () => import('@/pages/SA8000/index.vue')
        },
        {
          path: '/Novo',
          name: 'Novo',
          component: () => import('@/pages/Novo/index.vue')
        },
        {
          path: '/TIR',
          name: 'TIR',
          component: () => import('@/pages/TIR/index.vue')
        },
        {
          path: '/Majdanpek',
          name: 'Majdanpek',
          component: () => import('@/pages/Majdanpek/index.vue')
        },
        {
          path: '/Jama',
          name: 'Jama',
          component: () => import('@/pages/Jama/index.vue')
        },
        {
          path: '/Veliki',
          name: 'Veliki',
          component: () => import('@/pages/Veliki/index.vue')
        },
        {
          path: '/Line',
          name: 'Line',
          component: () => import('@/pages/Line/index.vue')
        },
        {
          path: '/Product',
          name: 'Product',
          component: () => import('@/pages/product/index.vue')
        },
        {
          path: '/Operation',
          name: 'Operation',
          component: () => import('@/pages/Operation/index.vue')
        },
        {
          path: '/Contact',
          name: 'Contact',
          component: () => import('@/pages/contact/index.vue')
        },
        {
          path: '/Jobs',
          name: 'Jobs',
          component: () => import('@/pages/jobs/index.vue')
        },
        {
          path: '/Safety',
          name: 'Safety',
          component: () => import('@/pages/Safety & Health/index.vue')
        },
        {
          path: '/Miners',
          name: 'Miners',
          component: () => import('@/pages/MinersDay/index.vue')
        },
        {
          path: '/Education',
          name: 'Education',
          component: () => import('@/pages/Education & Scholarship Programs/index.vue')
        },
        {
          path: '/Cultural',
          name: 'Cultural',
          component: () => import('@/pages/Cultural & Sports Events/index.vue')
        },
        {
          path: '/aboutUs',
          name: 'aboutUs',
          component: () => import('@/pages/aboutUs/index.vue')
        },
        {
          path: '/overview',
          name: 'overview',
          component: () => import('@/pages/overview/index.vue')
        },
        {
          path: '/Careers',
          name: 'Careers',
          component: () => import('@/pages/careers/index.vue')
        },
        {
          path: '/monitorComplaints',
          name: 'monitorComplaints',
          component: () => import('@/pages/monitorComplaints/index.vue')
        },
        {
          path: '/Information',
          name: 'Information',
          component: () => import('@/pages/Information/index.vue')
        },
        {
          path: '/PerformanceReport',
          name: 'PerformanceReport',
          component: () => import('@/pages/Information/index.vue')
        }
      ]
    }
  ]
})
router.afterEach(() => {
  // 滚动到页面顶部
  window.scrollTo(0, 0)
})
export default router
