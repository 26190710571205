import 'amfe-flexible';
import 'element-ui/lib/theme-chalk/index.css'; // 2.2引入样式
import 'swiper/dist/css/swiper.css';
import './assets/css/style.css';

import Vue from 'vue';

import ElementUI from 'element-ui'; // 2.1引入结构
import moment from 'moment';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import VueRouter from 'vue-router';

import App from './App.vue';
import i18n from './lang';
import router from './router';

Vue.config.productionTip = false
Vue.prototype.$moment = moment
Vue.use(VueRouter)
Vue.use(ElementUI) // 3.安装
Vue.use(VueAwesomeSwiper /* { 全局组件的默认选项 } */ )
new Vue({
    i18n,
    render: h => h(App),
    router: router
}).$mount('#app')