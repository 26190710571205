import Vue from 'vue';

import VueI18n from 'vue-i18n';

import en from './English.json';
import ser from './Serbian.json';

Vue.use(VueI18n)

const defaultLang = 'English'

const i18n = new VueI18n({
    locale: localStorage.getItem('locale') || defaultLang,
    fallbackLocale: defaultLang,
    messages: {
        English: en,
        Serbian: ser
    }
})

export default i18n